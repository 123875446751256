/**
 * 热卖商品相关API
 */

import request from '@/utils/request'
import mixins from '@/utils/mixin'
import Vue from 'vue'
let Component = Vue.extend({mixins:[mixins]})
let component = new Component()

/**
 * 下单商品数量排行Top30charts
 * @param params
 * @returns {Promise<any>}
 */
export function getHotGoodsNum(params) {
  return request({
    url: 'seller/statistics/goods/order_num',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 下单商品数量排行Top30table
 * @param params
 * @returns {Promise<any>}
 */
export function getHotGoodsNumPage(params) {
  return request({
    url: 'seller/statistics/goods/order_num_page',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 下单金额数量Top30charts
 * @param params
 * @returns {Promise<any>}
 */
export function getHotGoodsPrice(params) {
  return request({
    url: 'seller/statistics/goods/order_price',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 下单金额数量Top30table
 * @param params
 * @returns {Promise<any>}
 */
export function getHotGoodsPricePage(params) {
  return request({
    url: 'seller/statistics/goods/order_price_page',
    method: 'get',
    loading: false,
    params
  })
}

export function editCard(params) { // 编辑卡券
  return request({
    url: `seller/card/edit/${params.id}`,
    method: 'put',
    loading: false,
    params
  })
}

export function updateStatus(params) { // 上/下线
  return request({
    url: `seller/card/updateStatus`,
    method: 'put',
    loading: false,
    params
  })
}

export function delCard(params) { // 上/下线
  return request({
    url: `seller/card/delete`,
    method: 'post',
    loading: false,
    params
  })
}


export function getCardLogList(params) { // 生成卡券日志列表
  return request({
    url: `seller/card`,
    method: 'get',
    loading: false,
    params
  })
}

export function getcardKeysList(params) { // 查询卡密列表
  return request({
    url: `seller/card/cardKeysList`,
    method: 'get',
    loading: false,
    params
  })
}

export function updateCardsKeyStatus(params) { // 查询卡密列表
  return request({
    url: `seller/card/updateCardsKeyStatus`,
    method: 'put',
    loading: false,
    params
  })
}

export async function submitCreateCardKeys(params) { // 系统生成卡密
  let result = await component.MixinSetShopExtAuthList()
  let videoPath = result.toHiddenAuth.includes('shareVideoList') ? '' : '/video'
  return request({
    url: `seller/card${videoPath}/createCardKeys`,
    method: 'post',
    loading: true,
    params
  })
}

export async function submitImportCardKeys(data) {
  let result = await component.MixinSetShopExtAuthList()
  let videoPath = result.toHiddenAuth.includes('shareVideoList') ? '' : '/video'
  return request({
    url: videoPath ? `seller/card${videoPath}/importCardKeys` : `seller/card/importCardsKeys`,
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    loading: true,
    data
  })
}

export function getProvideLog(params) { // 查询卡券发放日志表列表
  return request({
    url: `seller/card/getProvideLog`,
    method: 'get',
    loading: false,
    params
  })
}

export function getMemberList(params) { // 在线发放系统用户表格
  return request({
    url: '/seller/members',
    method: 'get',
    params
  })
}

export function getUserlist(params) { // 查询用户分组列表
  return request({
    url: `/seller/members/memberGroup`,
    method: 'get',
    params
  })
}

export async function bindingMembers(params) { //在线卡卷发放系统
  let result = await component.MixinSetShopExtAuthList()
  let videoPath = result.toHiddenAuth.includes('shareVideoList') ? '' : '/video'
  return request({
    url: `seller/card${videoPath}/bindingMembers`,
    method: 'put',
    data: params
  })
}

export async function importBindingMembers(data) { //导入绑定卡密    发放
  let result = await component.MixinSetShopExtAuthList()
  let videoPath = result.toHiddenAuth.includes('shareVideoList') ? '' : '/video'
  return request({
    url: videoPath ? `seller/card${videoPath}/importBindingMembers` : `seller/card/SXShop/bindingImport`,
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    loading: true,
    data
  })
}
