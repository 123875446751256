<template>
	<div class="grant">
		<div class="titleTop">
			<el-button type="primary" @click="handleDelAllClick" size="small">删除选中项</el-button>
			<el-button type="primary" @click="goAddPage" size="small">添加</el-button>
		</div>
		<el-table
      :row-key="row => row.id"
      :data="marketList"
      @selection-change="marketListChange"
      border style="width: 100%">

      <el-table-column :reserve-selection="true" type="selection" width="55"></el-table-column>
      <el-table-column label="卡券二维码">
        <template slot-scope="scope"><img class="qrcode" :src="scope.row.qr_img_url" /></template>
      </el-table-column>
      <el-table-column label="卡券名称">
        <template slot-scope="scope">{{ scope.row.card_name }}</template>
      </el-table-column>
      <el-table-column label="卡券金额">
        <template slot-scope="scope">{{ scope.row.card_value }}</template>
      </el-table-column>
      <el-table-column label="激活卡数">
        <template slot-scope="scope">{{ scope.row.active_num }}/{{scope.row.key_num}}</template>
      </el-table-column>
      <el-table-column label="已激活卡总金额">
        <template slot-scope="scope">￥{{ scope.row.card_value * scope.row.active_num }}</template>
      </el-table-column>
      <el-table-column label="创建时间" width="160">
        <template slot-scope="scope">{{ scope.row.create_time | unixToDate('yyyy-MM-dd hh:mm') }}</template>
      </el-table-column>
      <el-table-column label="有效截止期" width="160">
        <template slot-scope="scope">{{ scope.row.end_time | unixToDate('yyyy-MM-dd hh:mm') }}</template>
      </el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">{{ scope.row.status == 1 ? '已上线' : '已下线' }}</template>
      </el-table-column>
      <el-table-column label="操作" width="300">
        <template slot-scope="scope">
          <el-button v-if="scope.row.status != 1"  size="mini" type="primary" @click="handleEditClick(scope.row)">编辑</el-button>
          <el-button size="mini" type="primary" @click="goGrantPage(scope.row)">发放</el-button>
          <el-button v-if="scope.row.status == 1" size="mini" type="primary" @click="checkStatusClick(scope.row,0)">下线</el-button>
          <el-button v-else size="mini" type="primary" @click="checkStatusClick(scope.row,1)">上线</el-button>
          <el-button size="mini" type="primary" @click="handleDelClick(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="text-align:center;margin-top:10px;" class="pages">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-size="params.page_size"
        :page-sizes="MixinPageSizes"
        :layout="MixinTableLayout"
        background
        :total="params.total">
      </el-pagination>
    </div>

		<!--添加标签类型-->
		<el-dialog title="编辑卡券" :visible.sync="dielog" width="50%">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="110px" class="demo-ruleForm">
        <el-form-item label="卡券名称" prop="card_name">
          <el-input v-model="ruleForm.card_name"></el-input>
        </el-form-item>
        <el-form-item label="有效截至时间" prop="end_time">
          <el-date-picker
            type="date"
            placeholder="选择日期"
            v-model="ruleForm.end_time"
            style="width: 100%;"
            value-format="timestamp"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="所属销售公司" prop="company">
          <el-input v-model="ruleForm.company"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitEditClick('ruleForm')">确定</el-button>
          <el-button>取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
	import * as API_HotGoods from '@/api/hotGoods'
	// import { handleDownload } from '@/utils'
	// import Sortable from 'sortablejs'
	import { UE } from '@/components'
	import * as API_coupon from "@/api/coupon";
	export default {
		name: 'hotSellingGoods',
		components: { [UE.name]: UE },
		data() {
			return {
        marketList: [],
        selectMarketList:[],
        dielog:false,
        stepActive:1,
        ruleForm:{
          id:'',
          card_type:'',
          card_name:'',
          end_time:'',
          company:'',
        },
        rules:{
          card_name:[
            { required: true, message: '请输入卡券名称', trigger: 'blur' },
          ],
          end_time:[
            { type: 'date', required: true, message: '请选择日期', trigger: 'change' }
          ],
          company:[
            { required: true, message: '请输入公司名称', trigger: 'blur' },
          ],
        },
        params: {
					page_no: 1,
					page_size: 20,
					total: 0
        },
      }
    },
    mounted() {
      this.searchCard();
    },
    methods: {
      goAddPage(){ // 跳转至添加页面
        this.$router.push({path:'/application/add'})
      },
      goGrantPage(row){ // 跳转至发放页面
        this.$router.push({
          path:'/application/grant',
          query:{
            id:row.id
          }
        })
      },

      handleSizeChange(val){ // 修改每页条数
        this.params.page_size = val
        this.searchCard()
      },
      handleCurrentChange(val){ // 当前页数
        this.params.page_no = val
        this.searchCard()
      },
      marketListChange(e){// 选择数据
        this.selectMarketList = e
      },
      searchCard() { //搜索卡券信息
        this.params.card_type = 1;
        API_coupon.getDhmlist(this.params).then(res => {
          this.marketList = res.data;
          this.loading = false;
          this.params.page_no = res.page_no;
          this.params.page_size = res.page_size;
          this.params.total = res.data_total;
        });
      },
      marketListChange(e) {
        console.log(e);
        this.selectMarketList = e;
      },
      handleEditClick(row) {
        //使用跳转编辑
        this.$router.push('/application/add?card_id=' + row.id)
        return
        // 点击编辑按钮
        this.ruleForm.id = row.id;
        this.ruleForm.card_type = row.card_type;
        this.ruleForm.card_name = row.card_name;
        this.ruleForm.start_time = row.start_time;
        this.ruleForm.end_time = row.end_time;
        this.ruleForm.company = row.company;
        this.dielog = true;
      },
      submitEditClick() {// 编辑
        API_HotGoods.editCard(this.ruleForm).then(res => {
          this.$message({
            message: "编辑成功",
            type: "success"
          });
          this.ruleForm.id = row.id;
          this.ruleForm.card_type = "";
          this.ruleForm.card_name = "";
          this.ruleForm.start_time = "";
          this.ruleForm.end_time = "";
          this.ruleForm.company = "";
          this.dielog = false;
          this.searchCard();
        });
      },
      checkStatusClick(row, status) {// 上/下线
        API_HotGoods.updateStatus({
          id: row.id,
          status
        }).then(res => {
          this.$message({
            message: status === 1 ? "已上线" : "已下线",
            type: "success"
          });
          this.searchCard();
        });
      },
      handleDelClick(row) {// 删除
        if (row.status == 1) {
          this.$message({
            message: "无法删除已上线卡券，请先进行下线操作",
            type: "warning"
          });
        } else {
          this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          })
            .then(() => {
              let arr = [];
              arr.push(row.id);
              API_HotGoods.delCard({ ids: arr }).then(res => {
                this.$message({ message: "删除成功", type: "success" });
                this.searchCard();
              });
            })
            .catch(() => {
              this.$message({ type: "info", message: "已取消删除" });
            });
        }
      },
      handleDelAllClick() {
        // 批量删除
        let bolean = false;
        let arr = [];
        this.selectMarketList.map(item => {
          if (item.status == 1) {
            bolean = true;
          }
          arr.push(item.id);
        });
        if (arr.length > 0) {
          if (bolean) {
            this.$message({
              type: "warning",
              message: "请先确认已选择数据，已上线卡券无法删除"
            });
          } else {
            this.$confirm("此操作将永久删除选中, 是否继续?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning"
            })
              .then(res => {
                API_HotGoods.delCard({ ids: arr }).then(res => {
                  this.$message({ message: "批量删除成功", type: "success" });
                  this.selectMarketList = [];
                  this.searchCard();
                });
              })
              .catch(() => {
                this.$message({ type: "info", message: "已取消删除" });
              });
          }
        } else {
          this.$message({ type: "warning", message: "请先选择需要操作的数据" });
        }
      },
		}
	}
</script>

<style lang="scss" scoped>
.grant {
  background: #fff;
  padding: 20px;
  border-radius: 5px;
}
.stepsBox {
  margin-top: 20px;
}
.dialog-footer {
  float: right;
  margin-top: 20px;
}
.tipBox {
  width: 440px;
  background: rgba(242, 242, 242, 1);
  border-radius: 6px;
  padding: 10px 20px;
  margin-bottom: 20px;
}
.tipBox p {
  line-height: 30px;
  font-size: 14px;
  color: #666;
  margin: 0;
}
.tipBox.b1 p {
  line-height: 24px;
  font-size: 12px;
}
.tipBox.b2 {
  width: 550px;
}
.tipBox.b2 p {
  line-height: 24px;
  font-size: 12px;
}
.shep {
  margin-bottom: 15px;
}

.titleTop {
  margin-bottom: 10px;
}
.titleTop .right {
  float: right;
}
.sTitle {
  margin-right: 10px;
}
.qrcode{
  width: 100%;
}
</style>
